import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MyImage = makeShortcode("MyImage");
const InfoBlock = makeShortcode("InfoBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We will now use your working copies to let CodeStory know about your project's repositories, then optionally specify how your code is organized.`}</p>
    <MyImage src="Codeview.png" alt="Instructions" mdxType="MyImage">
  CodeStory's most powerful feature is the ability to embed live views from your codebase into your notes.
    </MyImage>
    <h3 {...{
      "id": "open-the-project-and-follow-the-instructions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#open-the-project-and-follow-the-instructions",
        "aria-label": "open the project and follow the instructions permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Open the project and follow the instructions.`}</h3>
    <MyImage src="Getting_started_5.png" alt="Instructions" preset="no-border" mdxType="MyImage" />
    <h3 {...{
      "id": "open-the-repository-panel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#open-the-repository-panel",
        "aria-label": "open the repository panel permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Open the repository panel`}</h3>
    <p>{`... and add a repository.`}</p>
    <MyImage src="Getting_started_6.png" alt="Repository Panel" mdxType="MyImage" />
    <h3 {...{
      "id": "locate-your-projects-working-copy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#locate-your-projects-working-copy",
        "aria-label": "locate your projects working copy permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Locate your project's working copy`}</h3>
    <MyImage src="Getting_started_7.png" alt="New Repository" mdxType="MyImage" />
    <p>{`If you successfully selected the working copy, CodeStory was able to use git to determine the remote:`}</p>
    <MyImage src="Getting_started_8.png" alt="New Repository" mdxType="MyImage" />
    <p>{`By clicking the `}<strong parentName="p">{`Add Repository`}</strong>{` button:`}</p>
    <ul>
      <li parentName="ul">{`the `}<strong parentName="li">{`repository`}</strong>{` information (name and remote) is associated to your CodeStory project and `}<strong parentName="li">{`saved to the cloud`}</strong>{` for your team as well,`}</li>
      <li parentName="ul">{`the `}<strong parentName="li">{`working copy location`}</strong>{` is `}<strong parentName="li">{`saved locally`}</strong>{`, just for you.`}</li>
    </ul>
    <h3 {...{
      "id": "enter-editing-mode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#enter-editing-mode",
        "aria-label": "enter editing mode permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Enter editing mode`}</h3>
    <p>{`We will now further configure the repository in order to specify the code sources.`}</p>
    <InfoBlock mdxType="InfoBlock">
  This configuration step is of different nature than the previous one, as it is subject to change over time, depending
  on your git branches and commits!
    </InfoBlock>
    <p>{`Toggle the switch in the upper right corner of the window (or use `}<strong parentName="p">{`Cmd-T`}</strong>{`).`}</p>
    <MyImage src="Getting_started_10.png" alt="Switch" style={{
      margin: 'auto',
      width: '5em'
    }} mdxType="MyImage" />
    <p>{`A `}<strong parentName="p">{`Project Settings icon`}</strong>{` is revealed. Click it.`}</p>
    <h3 {...{
      "id": "open-the-project-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#open-the-project-settings",
        "aria-label": "open the project settings permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Open the project settings`}</h3>
    <p>{`All your project repositories are shown here:`}</p>
    <MyImage src="Getting_started_11.png" alt="Project Settings" mdxType="MyImage">
  No source is set yet! Click to edit your repository settings.
    </MyImage>
    <h3 {...{
      "id": "edit-the-repository-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#edit-the-repository-settings",
        "aria-label": "edit the repository settings permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Edit the repository settings`}</h3>
    <MyImage src="Getting_started_12.png" alt="Project Settings" mdxType="MyImage">
  The repository settings were created with default settings, but no assumption is made regarding where your source
  files are located inside that repository.
    </MyImage>
    <h3 {...{
      "id": "add-a-source",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-source",
        "aria-label": "add a source permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Add a source`}</h3>
    <p>{`Click `}<strong parentName="p">{`Add Source`}</strong>{` and locate the subfolder of your working copy where your source files live.`}</p>
    <MyImage src="Getting_started_13.png" alt="Project Settings" mdxType="MyImage">
      <p>{`  In this example, the source is simply set to be the root of the working copy. `}<br />{`Please note that `}<inlineCode parentName="p">{`/dist`}</inlineCode>{` is
excluded here using a glob exclusion.`}</p>
    </MyImage>
    <p>{`If that makes sense, your can define additional sources, even nested ones.`}</p>
    <p>{`For example, in that other project, 3 sources were defined.`}</p>
    <MyImage src="PGPilot2settings.png" alt="PGPilot Settings" mdxType="MyImage" />
    <MyImage src="PGPilot2arrows.png" alt="PGPilot" mdxType="MyImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      